import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '@components';
import { ErrorBoundary, Menu } from '../';

import './Layout.scss';
import { Modal } from '@mui/material';
import {
  BrochureBotsModal,
  BrochurePdfModal,
  ConsultationModal,
} from '@components/Modals';
import { useDispatch, useSelector } from 'react-redux';
import { selectedDealer } from '@store/dealers/slice';
import {
  hideConsultModal,
  selectIsShowBrochureBotsModal,
  selectIsShowBrochurePdfModal,
  selectIsShowConsultModal,
} from '@store/modals/slice';
import {useGetModalDataQuery} from "@store/modals/api";

const MainLayout = () => {
  const dispatch = useDispatch();
  const dealerCode = useSelector(selectedDealer);
  const isShowConsultationModal = useSelector(selectIsShowConsultModal);
  const isShowBrochureBotsModal = useSelector(selectIsShowBrochureBotsModal);
  const isShowBrochurePdfModal = useSelector(selectIsShowBrochurePdfModal);
  const { data: timeoutModalData } = useGetModalDataQuery({});

  const onHideConsultModal = () => dispatch(hideConsultModal());
  const onHideBrochureBotsModal = () => dispatch(hideConsultModal());
  const onHideBrochurePdfModal = () => dispatch(hideConsultModal());

  return (
    <div className="wrapper">
      <Menu />
      <div className="right">
        <Header
          showComprassionsButton={true}
          showConfigurationButton={true}
          showAskButton={timeoutModalData?.data?.[0]?.timer_settings?.show_in_front == 1}
        />
        <main className="main">
          <ErrorBoundary>
            <Outlet />

            <Modal
              open={isShowConsultationModal}
              onClose={onHideConsultModal}
              disableAutoFocus={true}
            >
              <ConsultationModal
                closeModal={onHideConsultModal}
                dealerCode={dealerCode || ''}
              />
            </Modal>
            <Modal
              open={isShowBrochureBotsModal}
              onClose={onHideBrochureBotsModal}
              disableAutoFocus={true}
            >
              <BrochureBotsModal closeModal={onHideBrochureBotsModal} />
            </Modal>
            <Modal
              open={isShowBrochurePdfModal}
              onClose={onHideBrochurePdfModal}
              disableAutoFocus={true}
            >
              <BrochurePdfModal closeModal={onHideBrochurePdfModal} />
            </Modal>
          </ErrorBoundary>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
